/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-3",
    "aws_appsync_graphqlEndpoint": "https://tvel3yc4izhddni6no5nnbx4ye.appsync-api.eu-west-3.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-3",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-6y66pexzzzfyzgfbib6bqcafl4",
    "aws_cognito_identity_pool_id": "eu-west-3:fbd60971-fdbb-4b22-86ab-761edb21a009",
    "aws_cognito_region": "eu-west-3",
    "aws_user_pools_id": "eu-west-3_k60hwWGMf",
    "aws_user_pools_web_client_id": "spdkv1iv13lpgjvtpuld8elob",
    "oauth": {
        "domain": "us6s7hwtxai0-prod.auth.eu-west-3.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://localhost:3000/",
        "redirectSignOut": "https://localhost:3000/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE"
    ],
    "aws_cognito_signup_attributes": [],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS",
            "REQUIRES_UPPERCASE"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "gtla-storage-88691f07145011-prod",
    "aws_user_files_s3_bucket_region": "eu-west-3"
};


export default awsmobile;
